import { globalProps } from "@/main";

export const getTableData = async () => {
    const url = `${globalProps.$apiUrl}/academicPublication/getLatestTenAcademicPublication`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
}

export const getBottomPublicationFaculties = async () => {
    const url = `${globalProps.$apiUrl}/academicPublication/getBottomPublicationFaculties`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
}

export const getTopPublicationFaculties = async () => {
    const url = `${globalProps.$apiUrl}/academicPublication/getTopPublicationFaculties`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
}

export const getBottomPublicationDepartment = async () => {
    const url = `${globalProps.$apiUrl}/academicPublication/getBottomPublicationDepartment`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
}


export const getTopPublicationDepartment = async () => {
    const url = `${globalProps.$apiUrl}/academicPublication/getTopPublicationDepartment`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
}

export const getPublicationCountYears = async () => {
    const url = `${globalProps.$apiUrl}/academicPublication/getPublicationCountYears`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
}

export const getPublisherCount = async () => {
    const url = `${globalProps.$apiUrl}/academicPublication/getPublisherCount`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
}

export const getPubTypeBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/academicPublication/getPubTypeBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
}

export const getSparklineData = async () => {
    const url = `${globalProps.$apiUrl}/academicPublication/getSparklineData`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
}
