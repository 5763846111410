import { globalProps } from "@/main";

export const getMBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/irpp/getMBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getSchoolSourceFundsBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/irpp/getSchoolSourceFundsBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getExchangeBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/irpp/getExchangeBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getVisitorBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/irpp/getVisitorBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getNotableActivitiesBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/irpp/getNotableActivitiesBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};