import { globalProps } from "@/main";

export const getLibraryResourceBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/library/getLibraryResourceBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getElectronicResourceBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/library/getElectronicResourceBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getUserBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/library/getUserBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getAlternativeResourceBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/library/getAlternativeResourceBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getBorrowerFullBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/library/getBorrowerFullBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getBorrowerBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/library/getBorrowerBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};