
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { globalProps } from "@/main";
import { ArrowPathRoundedSquareIcon} from '@heroicons/vue/24/outline';
import ChartModel from '@/components/charts/ChartModel.vue';
import { getElectricityRateBreakdown, getMonthlyPaymentBreakdown, 
    getMonthlyDieselBreakdown, getMonthlyDieselCostBreakdown  } from '@/service/power.service';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel
    },
    data() {
        return  {
            interval: 0,
            timeout: 0,
            chart1: {
                options: {}
            },
            chart2: {
                options: {}
            },
            chart3: {
                options: {}
            },
            chart4: {
                options: {}
            },
        }
    },
    mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }

        this.refreshPowerSupplyData();

        if(this.$route.name == "powerSupply")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshPowerSupplyData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"programmes"})}, globalProps.$change_page_frequency_seconds);

        }
        else{
            clearInterval(this.interval);
        }
    },
    methods: {
        async refreshPowerSupplyData() {
            if(this.$route.name == "powerSupply"){
                this.createChartOne();
                this.createChartTwo();
                this.createChartThree();
                this.createChartFour();
            }
        },
        async createChartOne(){
            const data = await getElectricityRateBreakdown();

            if(data != null || data != undefined){
                this.chart1.options = {
                    series: data.data,
                    chart: {
                        id:'c1',
                        group: 'electricity',
                        type: 'line',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight',
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    yaxis:{
                        title:{
                            text:"Litres"
                        }
                    },
                    theme: {
                        palette: 'palette9'
                    }
                };
            }
        },
        async createChartTwo(){
            const data = await getMonthlyPaymentBreakdown();

            if(data != null || data != undefined){
                this.chart2.options = {
                    series: data.data,
                    chart: {
                        id:'c2',
                        group: 'electricity',
                        type: 'area',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    yaxis:{
                        title:{
                            text:"Amount"
                        }
                    },
                    theme: {
                        palette: 'palette9'
                    }
                };
            }
        },
        async createChartThree(){
            const data = await getMonthlyDieselBreakdown();

            if(data != null || data != undefined){
                this.chart3.options = {
                    series: data.data,
                    chart: {
                        id:'c3',
                        group: 'diesel',
                        type: 'line',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight',
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    yaxis:{
                        title:{
                            text:"Litres"
                        }
                    },
                    theme: {
                        palette: 'palette9'
                    }
                };
            }
        },
        async createChartFour(){
            const data = await getMonthlyDieselCostBreakdown();

            if(data != null || data != undefined){
                this.chart4.options = {
                    series: data.data,
                    chart: {
                        id:'c4',
                        group: 'diesel',
                        type: 'area',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    yaxis:{
                        title:{
                            text:"Amount"
                        }
                    },
                    theme: {
                        palette: 'palette9'
                    }
                };
            }
        },
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
});
