import { globalProps } from "@/main";

export const getNoOfDepartments = async () => {
    const url = `${globalProps.$apiUrl}/department/getNoOfDepartments`;

    
    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getNoOfFaculties = async () =>{
    const url = `${globalProps.$apiUrl}/department/getNoOfFaculties`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    })
};

export const getDepartmentCountByFacultyBreakdownTable  = async () =>{
    const url = `${globalProps.$apiUrl}/faculty/getDepartmentCountByFaculty`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    })
};

export const getNoAccommodatationFacilitiesPerYearGraph  = async () =>{
    const url = `${globalProps.$apiUrl}/faculty/getNoAccommodatationFacilitiesPerYear`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    });
};

export const getNoAccomodationFacilitiesBySexYearBar  = async () =>{
    const url = `${globalProps.$apiUrl}/accommodation/getNoAccomodationFacilitiesBySexYear`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    });
};

export const getTotalAccommodationByYearGender  = async () =>{
    const url = `${globalProps.$apiUrl}/accommodation/getTotalAccommodationByYearGender`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    });
};

export const getTotalEnergyConsumedByYearGender  = async () =>{
    const url = `${globalProps.$apiUrl}/accommodation/getTotalEnergyConsumedByYearGender`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    });
};

export const getNoApplicantsVsNoEnrolledPerYear  = async () =>{
    return null;
}

export const getNoStudentDegreeVsPrefferedUniversity  = async () =>{
    return null;
}

export const getForeignvsLocalUniversity  = async () =>{
    return null;
}

export const getMisPerYear  = async () =>{
    return null;
}

export const getVolumePerYearBreakdown  = async () =>{
    return null;
}

export const getGraduateVsPostGraduatePerYear  = async () =>{
    return null;
}

export const getBorrowsCount  = async () =>{
    return null;
}

export const getFoundationUniversityBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/foundation/getFoundationUniversityBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    });
};

export const getFoundationAppliedEnrolledBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/foundation/getFoundationAppliedEnrolledBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    });
};

export const getExternalEnergyBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/energy/getExternalEnergyBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    });
};

export const getLibraryUsersBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/library/getLibraryUsersBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    });
};


export const getStudentStateBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getStudentStateBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    });
};

export const getPublicationCountByDepartmentPerYearBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/academicPublication/getPublicationCountByDepartmentPerYearBreakdown`;
    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {
        console.log(error); 
        return undefined;
    });
};

