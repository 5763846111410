import { globalProps } from "@/main";

export const getAlumniBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/ooa/getAlumniBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getScholarshipBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/ooa/getScholarshipBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getBursarySupportBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/ooa/getBursarySupportBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getProfChairBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/ooa/getProfChairBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getProfChairValueBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/ooa/getProfChairValueBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};