import { resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center md:px-6 md:py-4" }
const _hoisted_2 = { class: "h-2/5 py-3 flex flex-col" }
const _hoisted_3 = { class: "h-3/5 w-full flex flex-col py-3" }
const _hoisted_4 = { class: "shrink leading-3 tracking-tighter font-mono font-bold text-sm justify-center capitalize md:text-xl" }
const _hoisted_5 = { class: "shrink leading-3 tracking-tighter font-mono font-thin text-xs justify-center md:text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Autocounter = _resolveComponent("Vue3Autocounter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.customComponent), {
        class: _normalizeClass(["h-16 justify-center text-blue-500", [_ctx.customIconClass]])
      }, null, 8, ["class"])), [
        [_vShow, _ctx.customComponent != null]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        _createVNode(_component_Vue3Autocounter, {
          ref: _ctx.id,
          startAmount: 500,
          endAmount: _ctx.endCount,
          duration: 5,
          separator: ","
        }, null, 8, ["endAmount"])
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.caption), 1)
    ])
  ]))
}