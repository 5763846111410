 
import { defineComponent } from 'vue';
import { authenticateUser } from '@/service/authentication.service';

export default defineComponent({
  data() {
        return {
          username:"",
          password:"",
          validUser : true
        };
    },
    methods: {
      async onClick()
      {
        let username = this.username;
        let password = this.password;

        let crendential = {"username": username, "password":password};

        const authenticated = await authenticateUser(crendential);

        if(authenticated != null || authenticated != undefined)
        {
          sessionStorage.setItem("user", JSON.stringify(authenticated));
          this.$router.push({path:"/dashboard"})
        }
        else{
          this.validUser = false;
        }
      },
      removeErrorText()
      {
        if(!this.validUser)
        {
          this.validUser = true;
        }
      }
    }

});
