
import { defineComponent } from 'vue';
import { globalProps } from "@/main";
import NavBar from '@/components/nav/NavBar.vue';
import ChartModel from '@/components/charts/ChartModel.vue';
import Dropdown from 'primevue/dropdown';
import  ApexCharts from 'apexcharts';
import {getTotalAccommodationCapacity, getTotalAccommodationEnergyConsumed, getAccommodationStats,
    getAccommodationHallNames, getAccommodationLocations, getAccommodationDataByHall, getAccommodationDataByLocation,
    getEnergyConsumptionDataByHall, getEnergyConsumptionDataByLocation} from '@/service/accommodation.service'
import { ArrowPathRoundedSquareIcon, HomeModernIcon, MapPinIcon} from '@heroicons/vue/24/outline';
import StatChartModelVue from '@/components/charts/StatChartModel.vue';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel, StatChartModelVue, Dropdown
    },
    data () {
        return {
            interval:0,
            timeout: 0,
            chart1: {
                residenceCount: 1,
                residenceIcon: HomeModernIcon,
                ActiveCount: 1,
                ActiveIcon: HomeModernIcon,
                LocationCount: 1,
                LocationIcon: MapPinIcon
            },
            chart2: {
                options: {}
            },
            chart3: {
                show: false,
                selectedHall: {},
                selectedHallOptions: []
            },
            chart4:{
                show:false,
                selectedLocation: {},
                selectedLocationOptions: []
            },
            chart5: {
                options: {}
            },
            chart6: {
                show:false,
                selectedHall: {},
                selectedHallOptions: []
            },
            chart7: {
                show:false,
                selectedLocation: {},
                selectedLocationOptions: []
            }
        }
    }, 
    async mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }
        
        this.createChartOne();
        this.createChartTwo();
        this.createChartThree("all");
        this.createChartFour("all");
        this.createChartFive();
        this.createChartSix("all");
        this.createChartSeven("all");

        if(this.$route.name === "accommodation")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshAccData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"capitalProjects"})}, globalProps.$change_page_frequency_seconds);
        }
        else{
            clearInterval(this.interval);
        }
    },
    async created(){
    
        const chartThreeData = await getAccommodationHallNames();
        if(chartThreeData != null || chartThreeData != undefined)
        {
            this.chart3.selectedHall = {"name":"all", "value":"all"};
            this.chart3.selectedHallOptions = chartThreeData.data;
            this.chart6.selectedHall = {"name":"all", "value":"all"};
            this.chart6.selectedHallOptions = chartThreeData.data;
        }

        const chartFourData = await getAccommodationLocations();
        if(chartFourData != null || chartFourData != undefined)
        {
            this.chart4.selectedLocation = {"name":"all", "value":"all"};
            this.chart4.selectedLocationOptions = chartFourData.data;
            this.chart7.selectedLocation = {"name":"all", "value":"all"};
            this.chart7.selectedLocationOptions = chartFourData.data;
        }
    },
    methods: {
        async refreshAccData() {

            if(this.$route.name == "accommodation")
            {
                (this.$refs["t1"] as any).reRunAnimation();
                (this.$refs["t2"] as any).reRunAnimation();
                (this.$refs["t3"] as any).reRunAnimation();

                this.createChartTwo();
                this.createChartThree((this.chart3.selectedHall as any).value);
                this.createChartFour((this.chart4.selectedLocation as any).value);
                this.createChartFive();
                this.createChartSix((this.chart6.selectedHall as any).value);
                this.createChartSeven((this.chart7.selectedLocation as any).value);
            }
            
        },
        async createChartOne(){
            const chartOneData = await getAccommodationStats();
            if(chartOneData != null || chartOneData!=undefined)
            {
                this.chart1.residenceCount = chartOneData.data.residences;
                this.chart1.ActiveCount = chartOneData.data.activeResidences;
                this.chart1.LocationCount = chartOneData.data.locations;
            }

            const counter1 = (this.$refs["t1"] as any);
            const counter2 = (this.$refs["t2"] as any);
            const counter3 = (this.$refs["t3"] as any);

            if(counter1 != null || counter1 != undefined)
            {
                counter1.reRunAnimation()
            }

            if(counter2 != null || counter2 != undefined)
            {
                counter2.reRunAnimation()
            }

            if(counter3 != null || counter3 != undefined)
            {
                counter3.reRunAnimation()
            }
        },
        async createChartTwo(){
            const data = await getTotalAccommodationCapacity();

            if(data != null || data != undefined)
            {
                this.chart2.options = {
                    series: data.data,
                    chart: {
                        height: '100%',
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke:{
                        curve:'smooth'
                    },
                    xaxis:{
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    theme:{
                        palette:'palette3'
                    }
                };
            }
        },
        async createChartThree(hallname:string){
            const data = await getAccommodationDataByHall(hallname);

            if(data != null || data != undefined)
            {
                const options = {
                    series: data.data,
                    chart: {
                        height:"100%",
                        type: "bar"
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: "55%"
                        }
                    },
                    dataLabels:{
                        enabled: false
                    },
                    legend:{
                        show: true,
                    },
                    xaxis:{
                        type:'category',
                        labels:{
                            show:true
                        },
                        title:{
                            text:"Year"
                        },
                        categories: data.labels
                    },
                    theme:{
                        palette:'palette3'
                    }
                };

                this.chart3.show = true;

                let ctx = document.querySelector("#accChart3");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "accommodation"){
                    chart.render();
                }
            }
        },
        async createChartFour(location: string){
            const data = await getAccommodationDataByLocation(location);
            
            if(data != null || data != undefined)
            {
                const options ={
                    series: data.data,
                    chart: {
                        height:"100%",
                        type: "bar"
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: "55%"
                        }
                    },
                    dataLabels:{
                        enabled: false
                    },
                    legend:{
                        show: true,
                        showForSingleSeries: true
                    },
                    theme:{
                        palette:'palette3'
                    },
                    xaxis:{
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    }
                };

                this.chart4.show = true;

                let ctx = document.querySelector("#accChart4");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "accommodation"){
                    chart.render();
                }
            }
            
        },
        async createChartFive(){
            const data = await getTotalAccommodationEnergyConsumed();

            if(data != null || data!= undefined)
            {
                this.chart5.options = {
                    series: data.data,
                    chart: {
                        height: '100%',
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke:{
                        curve:'smooth'
                    },
                    xaxis:{
                        categories: data.labels,
                        title: {
                            text: "Year"
                        }
                    },
                    yaxis:{
                        title: {
                            text: "Energy Consumed (KWh)"
                        }
                    },
                    theme:{
                        palette:'palette3'
                    }
                }
            }
        },
        async createChartSix(hallname:string){
            const data = await getEnergyConsumptionDataByHall(hallname);

            if(data != null || data != undefined)
            {
                const options ={
                    series: data.data,
                    chart: {
                        height:"100%",
                        type: "bar"
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: "55%"
                        }
                    },
                    dataLabels:{
                        enabled: false
                    },
                    legend:{
                        show: true,
                    },
                    xaxis:{
                        labels:{
                            show:true
                        },
                        categories:data.label,
                        title:{
                            text:"Year"
                        }
                    },
                    theme:{
                        palette:'palette3'
                    }
                };

                this.chart6.show = true;

                let ctx = document.querySelector("#accChart6");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "accommodation"){
                    chart.render();
                }
            }
        },
        async createChartSeven(location: string){
            const data = await getEnergyConsumptionDataByLocation(location);
            
            if(data != null || data != undefined)
            {
                const options ={
                    series: data.data,
                    chart: {
                        height:"100%",
                        type: "bar"
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: "55%"
                        }
                    },
                    dataLabels:{
                        enabled: false
                    },
                    legend:{
                        show: true,
                        showForSingleSeries: true
                    },
                    xaxis:{
                        labels:{
                            show:true
                        },
                        categories:data.label,
                        title:{
                            text:"Year"
                        }
                    },
                    theme:{
                        palette:'palette3'
                    }
                };

                this.chart7.show = true;

                let ctx = document.querySelector("#accChart7");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "accommodation"){
                    chart.render();
                }
            }
            
        },
        accomodationHallChange(){
            const hallValue = (this.chart3.selectedHall as any).value;
            this.createChartThree(hallValue);
        },
        accomodationLocationChange(){
            const locationValue = (this.chart4.selectedLocation as any).value;
            this.createChartFour(locationValue);
        },
        energyHallChange(){
            const hallValue = (this.chart6.selectedHall as any).value;
            this.createChartSix(hallValue);
        },
        energyLocationChange(){
            const locationValue = (this.chart7.selectedLocation as any).value;
            this.createChartSeven(locationValue);
        }
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
});
