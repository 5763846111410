import { globalProps } from "@/main";

export const getGlancingFacts = async () => {
    const url = `${globalProps.$apiUrl}/academicStaff/getSummaryStats`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getDepartmentBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/academicStaff/getDepartmentBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getFacultyBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/academicStaff/getFacultyBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getGenderBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/academicStaff/getGenderBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getQualificationBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/academicStaff/getQualificationBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getDesignationBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/academicStaff/getDesignationBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getTechnologyTransferBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/academicStaff/getTechnologyTransferBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getEmploymentBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/academicStaff/getEmploymentBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getCountryBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/academicStaff/getCountryBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getStateBreakdown = async (country:string) => {
    const url = `${globalProps.$apiUrl}/academicStaff/getStateBreakdown?country=${country}`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};