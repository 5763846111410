
import { defineComponent } from 'vue';
import Vue3Autocounter from 'vue3-autocounter';

export default defineComponent({
    components: {Vue3Autocounter},
    props: {
        endCount: {
            type: Number,
            default: 0
        },
        caption: {
            type:String,
            default: "default Caption"
        },
        customComponent: {
            type: Object,
            default: null
        },
        customComponentClass: {
            type: String,
            defualt: ""
        }, 
        routeName: {
            type: String,
            required: true,
            default: ""
        },
        id: {
            type: String ,
            required: true,
            default: ""
        },
    },
    data (){
        return {
            customIconClass: this.customComponentClass
        }
    }, 
    mounted() {
        const counter = (this.$refs[this.id] as any);

        if(counter != null || counter!= undefined){
            counter.start();
        }
    },
    methods: {
        reRunAnimation(){
            const counter = this.$refs[this.id] as any;

            if(counter != null || counter!= undefined){
                counter.start();
            }
        }
    }
})
