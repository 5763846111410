
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { globalProps } from "@/main";
import { ArrowPathRoundedSquareIcon} from '@heroicons/vue/24/outline';
import ChartModel from '@/components/charts/ChartModel.vue';
import { getFinanceBreakdown, getRateEnergyBreakdown, getEnergySourceBreakdown, getVehicleDataBreakdown} from '@/service/energy.service';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel
    },
    data(){
        return {
            interval:0,
            timeout: 0,
            chart1:{
                options: {}
            },
            chart2:{
                options: {}
            },
            chart3:{
                options: {}
            },
            chart4: {
                options: {}
            }
        }
    },
    async mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        } 
        
        this.refreshEngData();

        if(this.$route.name === "energyConsumption")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshEngData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"faculty"})}, globalProps.$change_page_frequency_seconds);

        }
        else{
            clearInterval(this.interval);
        }
    },
    methods: {
        async refreshEngData() {

            if(this.$route.name === "energyConsumption")
            {
                this.createChartOne();
                this.createChartTwo();
                this.createChartThree();
                this.createChartFour();
            }
        },
        async createChartOne(){
            var data = await getFinanceBreakdown();

            if(data!= null || data!= undefined){
                this.chart1.options = {
                    series: data.data,
                    chart:{
                        type: 'polarArea',
                        height:'100%',
                        toolbar: {
                            show: true
                        }
                    },
                    stroke: {
                        colors: ['#fff']
                    },
                    fill: {
                        opacity: 0.8
                    },
                    labels: data.labels,
                    theme:{
                        palette: 'palette6'
                    }                  
                };
            }
        },
        async createChartTwo(){
            var data = await getRateEnergyBreakdown();

            if(data!= null || data!= undefined)
            {
                this.chart2.options= {
                    series: [
                        {
                            name:"Rate",
                            data: data.data
                        }
                    ],
                    chart:{
                        type: 'area',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    yaxis: {
                        title: {
                            text: "Amount of Energy (KWh)"
                        }
                    },
                    theme:{
                        palette: 'palette6'
                    }  
                };
            }
        },
        async createChartThree(){
            var data = await getEnergySourceBreakdown();

            if(data!= null || data != undefined)
            {
                this.chart3.options = {
                    series: data.data,
                    xaxis:{
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    yaxis:[
                        {
                            seriesName: 'Fuel',
                            axisTicks: {
                                show : true
                            },
                            axisBorder: {
                                show: true,
                                color: '#449DD1'
                            },
                            labels:{
                                style: {
                                    colors: '#449DD1'
                                }
                            },
                            title:{
                                text: "Fuel Consumed (Ltr)",
                                style: {
                                    color: '#449DD1'
                                }
                            }
                        },
                        {
                            seriesName: 'Disel',
                            opposite: true,
                            axisTicks: {
                                show : true
                            },
                            axisBorder: {
                                show: true,
                                color: '#F86624'
                            },
                            labels:{
                                style: {
                                    colors: '#F86624'
                                }
                            },
                            title:{
                                text: "Disel Consumed (Ltr)",
                                style: {
                                    color: '#F86624'
                                }
                            }
                        },
                        {
                            seriesName: 'National Grid',
                            opposite: true,
                            axisTicks: {
                                show : true
                            },
                            axisBorder: {
                                show: true,
                                color: '#EA3546'
                            },
                            labels:{
                                style: {
                                    colors: '#EA3546'
                                }
                            },
                            title:{
                                text: "Meter Units Consumed",
                                style: {
                                    color: '#EA3546'
                                }
                            },
                            tooltip:{
                                enabled: true
                            }
                        }
                    ],
                    chart:{
                        type: 'line',
                        height: '100%',
                        stacked: false
                    },
                    stroke: {
                        width: [1,1,5]
                    },
                    theme:{
                        palette: 'palette6'
                    }  
                }
            }
        },
        async createChartFour(){
            const data = await getVehicleDataBreakdown();

            if(data != null || data !=undefined)
            {
                this.chart4.options = {
                    series: data.data,
                    chart: {
                        type: 'bar',
                        height: '100%',
                        stacked: false
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    yaxis: [
                        {
                            seriesName: 'vehicles',
                            axisTicks: {
                                show : true
                            },
                            axisBorder: {
                                show: true,
                                color: '#449DD1'
                            },
                            labels:{
                                style: {
                                    colors: '#449DD1'
                                }
                            },
                            title:{
                                text: "Number of Vehicles",
                                style: {
                                    color: '#449DD1'
                                }
                            }
                        },
                        {
                            seriesName: 'Petrol Consumed (ltr)',
                            opposite: true,
                            axisTicks: {
                                show : true
                            },
                            axisBorder: {
                                show: true,
                                color: '#F86624'
                            },
                            labels:{
                                style: {
                                    colors: '#F86624'
                                }
                            },
                            title:{
                                text: "Petrol Consumed (ltr)",
                                style: {
                                    color: '#F86624'
                                }
                            }
                        }
                    ],
                    theme: {
                        palette: 'palette6'
                    }
                };
            }
        }
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
});
