
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { globalProps } from "@/main";
import { ArrowPathRoundedSquareIcon} from '@heroicons/vue/24/outline';
import ChartModel from '@/components/charts/ChartModel.vue';
import { getCompanyBreakdown, getIndustrialTrainingBreakdown } from '@/service/cilpu.service';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel
    },
    data(){
        return {
            interval:0,
            timeout:0,
            chart1: {
                options: {}
            },
            chart2: {
                options: {}
            }
        }
    },
    async mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }   

        this.refreshClipuData();

        if(this.$route.name === "cilpu")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshClipuData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"department"})}, globalProps.$change_page_frequency_seconds);

        }
        else{
            clearInterval(this.interval);
        }
    },
    methods: {
        async refreshClipuData() {
            if(this.$route.name == "cilpu")
            {
                this.createChartOne();
                this.createChartTwo();
            }
        },
        async createChartOne(){
            var data = await getIndustrialTrainingBreakdown();

            if(data!= null || data!= undefined)
            {
                this.chart1.options = {
                    series:[
                        {
                            name:'Number of Students',
                            data: data.data
                        }
                    ],
                    chart:{
                        type:'area',
                        height:'100%',
                    },
                    stroke:{
                        curve: 'straight'
                    },
                    yaxis:{
                        opposite:'true'
                    },
                    xaxis:{
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette10'
                    }
                };
            }


        },
        async createChartTwo(){
            var data =  await getCompanyBreakdown();

            if(data!= null || data!= undefined)
            {
                this.chart2.options = {
                    series: data.data,
                    chart:{
                        type:'line',
                        height: '100%'
                    },
                    stroke:{
                        curve:'smooth'
                    },
                    fill:{
                        type:'solid',
                        opacity: [0.35, 1]
                    },
                    labels: data.labels,
                    xaxis:{
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette10'
                    }
                };

            }

        }
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
});
