import { globalProps } from "@/main";

export const getFoundationAppliedEnrolledPerYearBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/foundation/getFoundationAppliedEnrolledPerYearBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getStudentProgressionBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/foundation/getStudentProgressionBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getStudentUniversityChoiceFullBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/foundation/getStudentUniversityChoiceFullBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getStudentUniversityChoiceBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/foundation/getStudentUniversityChoiceBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getStudentStaffBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/foundation/getStudentStaffBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};