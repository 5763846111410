
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { globalProps } from "@/main";
import { ArrowPathRoundedSquareIcon} from '@heroicons/vue/24/outline';
import ChartModel from '@/components/charts/ChartModel.vue';
import { getDepartureFacultyBreakdown, getAccreditationBreakdown, getCreationDateBreakdown} from '@/service/department.service';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel
    },
    data(){
        return {
            interval:0,
            timeout: 0,
            chart1:{
                options: {}
            },
            chart2:{
                options: {}
            },
            chart3: {
                options: {}
            }
        }
    },
    async mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        } 

        this.refreshDeptData();

        if(this.$route.name === "department")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshDeptData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"energyConsumption"}) }, globalProps.$change_page_frequency_seconds);
        }
        else{
            clearInterval(this.interval);
        }
    },
    methods: {
        async refreshDeptData() {
            this.createChartOne();
            this.createChartTwo();
            this.createChartThree();
        },
        async createChartOne(){
            const data = await getDepartureFacultyBreakdown();

            if(data != null || data != undefined)
            {
                this.chart1.options= {
                    series: [
                        {
                            name: "Number of Students",
                            data: data.data
                        }
                    ],
                    chart: {
                        type: 'bar',
                        height: data.labels.length * 25 > 1123 ? data.labels.length * 25 : '100%',
                    },
                    plotOptions:{
                        bar:{
                            borderRadius: 4,
                            horizontal: true,
                            columnWidth: '55%'
                        }
                    },
                    xaxis:{
                        categories: data.labels,
                        title:{
                            text:"Number of Students"
                        }
                    },
                    theme: {
                        palette: 'palette4'
                    }
                };
            }
        },
        async createChartTwo(){
            const data = await getAccreditationBreakdown();

            if(data != null || data != undefined)
            {
                this.chart2.options = {
                    series: data.data,
                    chart :{
                        type: 'donut',
                        toolbar: {
                            show: true
                        }
                    },
                    labels: data.labels,
                    theme: {
                        palette: 'palette4'
                    }
                };
            }
        },
        async createChartThree(){
            const data = await getCreationDateBreakdown();

            if(data != null || data!= undefined)
            {
                this.chart3.options = {
                    series:[
                        {
                            name: 'Number of faculties created',
                            data: data.data
                        }
                    ],
                    chart: {
                        type:'line',
                        height:'100%'
                    },
                    stroke:{
                        width: 9,
                        curve: 'straight',
                        dashArray: 7
                    },
                    xaxis:{
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette4'
                    }
                };
            }

        }
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }  
});
