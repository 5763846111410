
import { defineComponent } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,  } from '@headlessui/vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { ArrowTrendingUpIcon, BookOpenIcon, BuildingLibraryIcon, BuildingOffice2Icon, 
    WrenchScrewdriverIcon, ArrowLeftOnRectangleIcon, HomeModernIcon, BanknotesIcon, BoltIcon, Squares2X2Icon,
    TruckIcon, AcademicCapIcon, UserGroupIcon, BuildingOfficeIcon, CpuChipIcon, UsersIcon, 
    NewspaperIcon, GlobeEuropeAfricaIcon} from "@heroicons/vue/24/solid"


export default defineComponent ({
    name:"NavBar",
    data(){
        return {
            isDashboard: false,
            showModal: false,
        }
    },
    components: {
        Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
        ExclamationTriangleIcon, ArrowTrendingUpIcon, BookOpenIcon, 
        BuildingLibraryIcon, BuildingOffice2Icon, WrenchScrewdriverIcon, ArrowLeftOnRectangleIcon,
        HomeModernIcon, BanknotesIcon, BoltIcon, Squares2X2Icon, TruckIcon, AcademicCapIcon,
        UserGroupIcon, BuildingOfficeIcon, CpuChipIcon, UsersIcon, NewspaperIcon,
        GlobeEuropeAfricaIcon
    },
    methods: {
        logout(){
            this.showModal = !this.showModal;
        },
        confirmLogout(){
            sessionStorage.removeItem('user');
            this.$router.push({name:"login"});
            this.showModal = !this.showModal;
        }
    }
})
