
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { globalProps } from "@/main";
import { ArrowPathRoundedSquareIcon} from '@heroicons/vue/24/outline';
import ChartModel from '@/components/charts/ChartModel.vue';
import { getAlumniBreakdown, getScholarshipBreakdown, getBursarySupportBreakdown, 
    getProfChairBreakdown, getProfChairValueBreakdown} from '@/service/ooa.service';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel
    },
    data (){
        return {
            interval: 0,
            timeout: 0,
            chart1: {
                options: {}
            },
            chart2: {
                options: {}
            },
            chart3: {
                options: {}
            },
            chart4: {
                options: {}
            },
            chart5: {
                options: {}
            },
        }
    },
    mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }

        this.refreshOoaData();

        if(this.$route.name == "officeOfAdvancement")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshOoaData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"powerSupply"})}, globalProps.$change_page_frequency_seconds);

        }
        else{
            clearInterval(this.interval);
        }
    },
    methods: {
        async refreshOoaData() {

            if(this.$route.name == "officeOfAdvancement"){
                this.createChartOne();
                this.createChartTwo();
                this.createChartThree();
                this.createChartFour();
                this.createChartFive();
            }
        },
        async createChartOne(){
            const data = await getAlumniBreakdown();

            if(data != null || data != undefined){
                this.chart1.options = {
                    series: data.data,
                    chart: {
                        type: 'bar',
                        height: '100%'
                    },
                    plotOptions: {
                        bar: {
                            horizontal:false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        }
                    },
                    dataLabels:{
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    theme: {
                        palette: 'palette7'
                    }
                };
            }
        },
        async createChartTwo(){
            const data = await getScholarshipBreakdown();

            if(data != null || data != undefined){
                this.chart2.options = {
                    series: data.data,
                    chart: {
                        type: 'line',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight',
                    },
                    xaxis: {
                        categories: data.labels
                    },
                    theme: {
                        palette: 'palette7'
                    }
                };
            }
        },
        async createChartThree(){
            const data = await getBursarySupportBreakdown();

            if(data != null || data != undefined){
                this.chart3.options = {
                    series: data.data,
                    chart: {
                        type: 'area',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: data.labels
                    },
                    theme: {
                        palette: 'palette7'
                    }
                };
            }
        },
        async createChartFour(){
            const data = await getProfChairBreakdown();

            if(data != null || data != undefined){
                this.chart4.options = {
                    series: data.data,
                    chart: {
                        type: 'bar',
                        height: '100%'
                    },
                    plotOptions: {
                        bar: {
                            horizontal:false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        }
                    },
                    dataLabels:{
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: data.labels
                    },
                    fill: {
                        opacity: 1
                    },
                    theme: {
                        palette: 'palette7'
                    }
                };
            }
        },
        async createChartFive(){
            const data = await getProfChairValueBreakdown();

            if(data != null || data != undefined){
                this.chart5.options = {
                    series: data.data,
                    xaxis:{
                        categories: data.labels
                    },
                    chart: {
                        type: 'area',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        opacity: 0.8,
                        type: 'pattern',
                        pattern: {
                            style: ['verticalLines', 'horizontalLines'],
                            width: 5,
                            height: 6
                        }
                    },
                    theme: {
                        palette: 'palette7'
                    }
                }
            }
        },
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
});
