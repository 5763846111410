import { createApp } from 'vue';
import { Vue } from 'vue-class-component';
import App from './App.vue';
import './assets/css/tailwind.css';
import router from './router/routes';
import PrimeVue from 'primevue/config';
import AnimatedCounter from "vue-animated-counter"

const app = createApp(App);

fetch(process.env.BASE_URL + "config.json")
  .then((response) => response.json())
  .then((config) => {
    // either use window.config
    app.config.globalProperties.$apiUrl = config.api_url;
    app.config.globalProperties.$graphRefreshTimerMilliseconds = Number(config.graph_refresh_timer_milliseconds);
    app.config.globalProperties.$timeout = Number(config.timeout_seconds);
    app.config.globalProperties.$change_page_frequency_seconds = Number(config.change_page_frequency_seconds);
    // FINALLY, mount the app
    app.use(PrimeVue);
    app.use(router);
    app.mount("#app");
})

export const globalProps = app.config.globalProperties;

