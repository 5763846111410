
import { defineComponent } from 'vue';
import  ApexCharts from 'apexcharts';

export default defineComponent({

    props: {
        title: {
            type: String,
            default: "Default Title"
        },
        subTitle: {
            type: String,
            default: "Default Subtitle"
        },
        chartOptions: {
            type: Object,
            default: () => { return {}}
        },
        id: {
            type: String ,
            required: true,
            default: ""
        },
        routeName: {
            type: String,
            required: true,
            default: ""
        }
    },
    data() {
        return {
            show: false
        }
    },
    beforeUpdate() {
        // console.log(JSON.stringify(this.chartOptions))
        if(this.chartOptions != null && Object.keys(this.chartOptions).length > 0)
        {
            this.show = true;

            const chartId = `#${this.id}`

            let ctx = document.querySelector(chartId);
            if(ctx!== null)
            ctx.innerHTML = "";
            let chart = new ApexCharts(ctx, this.chartOptions);

            if(this.$route.name === this.routeName){
                chart.render();
            }
        }
    }

})
