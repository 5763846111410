
import { globalProps } from "@/main";
import NavBar from '@/components/nav/NavBar.vue';
import { defineComponent } from 'vue';
import  ApexCharts from 'apexcharts';
import { getTotalEnergyConsumedByYearGender, getDepartmentCountByFacultyBreakdownTable, getFoundationUniversityBreakdown, 
    getFoundationAppliedEnrolledBreakdown, getExternalEnergyBreakdown, getLibraryUsersBreakdown, getStudentStateBreakdown, 
    getPublicationCountByDepartmentPerYearBreakdown} from '@/service/dashboard.service';
import { ArrowPathRoundedSquareIcon} from '@heroicons/vue/24/outline';

export default defineComponent( {
    data() {
        return {
            interval:0,
            timeout: 0,
            showModal: false,
            chart1:{
                show: false,
                title: "",
                num:0,
                data: Object()
            },
            chart2:{
                show: false,
                title: "",
                num:0,
                data: Object()
            },
            chart3:{
                show: false,
                title: "",
                num:0,
                data: Object()
            },
            chart4:{
                show: false,
                title: "",
                num:0,
                data: Object()
            },
            chart5:{
                show: false,
                title: "",
                num:0,
                data: Object()
            },
            chart6:{
                show: false,
                num:0,
                title: "",
                data: Object()
            },
            chart7:{
                show: false,
                num:0,
                title: "",
                data: Object()
            },
            chart8:{
                show: false,
                title: "",
                num:0,
                data: Object()
            },
        }
    },
    mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }
        else
        {
            this.createChartOne();
            this.createChartTwo();
            this.createChartThree();
            this.createChartFour();
            this.createChartFive();
            this.createChartSix();
            this.createChartSeven();
            this.createChartEight();

            if(this.$route.name === "dashboard"){
                if(this.interval > 0){
                    clearInterval(this.interval);
                }

               this.interval = setInterval(this.refreshDashData, globalProps.$graphRefreshTimerMilliseconds );

               this.timeout = setTimeout(()=>{ this.$router.push({name:"academicPublication"})}, globalProps.$change_page_frequency_seconds);
            }
            else{
                clearInterval(this.interval);
            }
        }     
    },
    components:{
        NavBar,
        ArrowPathRoundedSquareIcon
    },
    methods: {
        async createChartOne(){
            const data = await getTotalEnergyConsumedByYearGender();
            if(data != null || data != undefined)
            {
                this.chart1.show = true;

                let series = [];
                for (const d of Object.keys(data.dataset)) {
                    let dataset = {
                        "name": d,
                        "data": data.dataset[d],
                    };
                    series.push(dataset);
                }

                const config = {
                    series: series,
                    chart: {
                        height: "100%",
                        type: 'line',
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        toolbar: {
                            show: true
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    title: {
                        text: 'Total Energy Consumption Per Year By Gender',
                        align: 'left'
                    },
                    xaxis: {
                        categories: data.labels,
                        title: {
                            text: 'Year'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'KWh'
                        },
                        min: 0,
                        max: 50000
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: 0,
                        offsetX: -5
                    },
                    theme: {
                        palette:"palette5"
                    }
                };

                this.chart1.show = true;

                let ctx = document.querySelector("#dashChart1");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart  = new ApexCharts(ctx, config);
                chart.render();

            }
        },
        async createChartTwo(){
            const data = await getDepartmentCountByFacultyBreakdownTable();

            if(data != null || data != undefined)
            {
                const config = {
                    chart:{
                        type: "bar",
                        height: data.label.length * 25 > 1050 ? data.label.length * 25 : '100%',
                    },
                    series: [
                        {
                            name: "No. Of Departments",
                            data: data.data
                        }
                    ],
                    title: {
                        text: 'Number of Departments Per Faculty',
                        align: 'left'
                    },
                    plotOptions: {
                        bar: {
                            horizontal:true,
                            borderRadius: 4,
                            columnWidth: '55%'
                        }
                    },
                    dataLabels: {
                        enabled:false
                    },
                    xaxis: {
                        categories: data.label,
                        title: {
                            text: "Number of Faculties"
                        }
                    },
                    // colors: ['rgb(235, 87, 87)']
                    theme:{
                        palette:'palette5'
                    }
                }

                this.chart2.show = true;

                let ctx = document.querySelector("#dashChart2");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, config);
                chart.render();
            }
        },
        async createChartThree(){

            const data = await getFoundationUniversityBreakdown();

            if(data != null || data != undefined)
            {
                let options = {
                    series: data.data,
                    labels: data.labels,
                    title: {
                        text: "Foundation - Dstr. After Grad",
                        align: "center"
                    },
                    chart: {
                        height: '100%',
                        width: '100%',
                        type: 'radialBar',
                        toolbar: {
                            show: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '10px',
                                },
                                value: {
                                    fontSize: '16px',
                                }
                            }
                        }
                    },
                    theme: {
                        palette:"palette5"
                    }
                };

                this.chart3.show = true;

                let ctx = document.querySelector('#dashChart3');
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);
                chart.render();
            }
        },
        async createChartFour(){
            const data = await getFoundationAppliedEnrolledBreakdown();

            if(data != null || data != undefined)
            {

                let options = {
                    series: data.data,
                    labels: data.labels,
                    title: {
                        text: "Foundation - Applied Vs Enrolled",
                        align: "center"
                    },
                    chart: {
                        height: '100%',
                        width: '100%',
                        type: 'radialBar',
                        toolbar: {
                            show: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '10px',
                                },
                                value: {
                                    fontSize: '16px',
                                }
                            }
                        }
                    },
                    theme: {
                        palette:"palette5"
                    }
                };

                this.chart4.show = true;

                let ctx = document.querySelector('#dashChart4')
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);
                chart.render();
            }
        },
        async createChartFive(){
            const data = await getExternalEnergyBreakdown();

            if(data != null || data != undefined)
            {
                let options = {
                    series: data.data,
                    labels: data.labels,
                    title: {
                        text: "Energy - Alternate Energy Consumed",
                        align: "center"
                    },
                    chart: {
                        height: '100%',
                        width: '100%',
                        type: 'radialBar',
                        toolbar: {
                            show: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '10px',
                                },
                                value: {
                                    fontSize: '16px',
                                }
                            }
                        }
                    },
                    theme: {
                        palette:"palette5"
                    }
                };

                this.chart5.show = true;

                let ctx = document.querySelector('#dashChart5');
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);
                chart.render();
            }
        }, 
        async createChartSix(){
            const data = await getLibraryUsersBreakdown();

            if(data != null || data != undefined)
            {
                const options = {
                    series: data.data.series,
                    labels: data.data.labels,
                    title: {
                        text: "Library Users",
                        align: "center"
                    },
                    chart: {
                        height: '100%',
                        width: '100%',
                        type: 'radialBar',
                        toolbar: {
                            show: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '10px',
                                },
                                value: {
                                    fontSize: '16px',
                                }
                            }
                        }
                    },
                    theme: {
                        palette:"palette5"
                    }
                };

                this.chart6.show = true;

                let ctx = document.querySelector('#dashChart6');
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);
                chart.render();
            }
        },
        async createChartSeven(){
            const data = await getStudentStateBreakdown();

            if(data != null || data != undefined)
            {
                let options = {
                    series: data.series,
                    legend: {
                        show: false
                    },
                    chart: {
                        height: '100%',
                        type: 'treemap'
                    },
                    title: {
                        text: 'Student State of Origin Distibuted Treemap',
                        align: 'center'
                    },
                    colors: [
                        '#3B93A5',
                        '#F7B844',
                        '#ADD8C7',
                        '#EC3C65',
                        '#CDD7B6',
                        '#C1F666',
                        '#D43F97',
                        '#1E5D8C',
                        '#421243',
                        '#7F94B0',
                        '#EF6537',
                        '#C0ADDB'
                    ],
                    plotOptions: {
                        treemap: {
                            distributed: true,
                            enableShades: false
                        }
                    }
                };

                this.chart7.show = true;

                let ctx = document.querySelector("#dashChart7");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);
                chart.render();
            }
        },
        async createChartEight(){
            const data = await getPublicationCountByDepartmentPerYearBreakdown();
            if(data != null || data != undefined)
            {
                const config = {
                    series: data.series,
                    chart: {
                        height: "90%",
                        type: 'line',
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        toolbar: {
                            show: true
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    title: {
                        text: 'Academic Publication By Departments',
                        align: 'left'
                    },
                    xaxis: {
                        type: 'category',
                        title: {
                            text: 'Year',
                            offsetX: -30,

                        }
                    },
                    yaxis: {
                        title: {
                            text: 'No. of Publications',
                            align: 'left'
                        }
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: 5,
                        offsetX: 0
                    },
                    theme: {
                        palette:"palette5"
                    }
                };
                this.chart8.show = true;

                let ctx = document.querySelector("#dashChart8");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart  = new ApexCharts(ctx, config);
                chart.render();
            }
        },
        async refreshDashData()
        {
            if(this.$route.name === "dashboard"){
                this.createChartOne();
                this.createChartTwo();
                this.createChartThree();
                this.createChartFour();
                this.createChartFive();
                this.createChartSix();
                this.createChartSeven();
                this.createChartEight();
            }
            
        }
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
});

