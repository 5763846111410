
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { ArrowPathRoundedSquareIcon} from '@heroicons/vue/24/outline';
import { globalProps } from "@/main";
import ChartModel from '@/components/charts/ChartModel.vue';
import { getPhysicalProjectsBreakdown, getCapitalProjectsIgrBreakdown,
    getCapitalProjectsFgnBreakdown,getCapitalProjectsTetfundBreakdown } from '@/service/capitalProjects.service';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel
    },
    data() {
        return {
            interval:0,
            timeout:0,
            chart1:{
                options: {}
            },
            chart2: {
                options: {}
            },
            chart3: {
                options: {}
            },
            chart4: {
                options: {}
            }
        }
    },
    async mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }
        this.refreshCpData();

        if(this.$route.name === "capitalProjects")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshCpData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"cilpu"})}, globalProps.$change_page_frequency_seconds);
        }
        else{
            clearInterval(this.interval);
        }
    },
    methods: {
        async refreshCpData() {
            if(this.$route.name === "capitalProjects"){
                this.createChartOne();
                this.createChartTwo();
                this.createChartThree();
                this.createChartFour();
            }
        },
        async createChartOne(){
            const data = await getPhysicalProjectsBreakdown();

            if(data!= null || data != undefined)
            {
                this.chart1.options = {
                    series: [
                        {
                        name: 'Number of Physical Projects',
                        data: data.data
                        }
                    ],
                    chart: {
                        type: 'bar',
                        height: '100%',
                    },
                    plotOptions:{
                        bar: {
                            distributed: true,
                            columnWidth: '55%'
                        }
                    },
                    dataLabels :{
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette2'
                    }
                };
            }
        },
        async createChartTwo(){
            const data = await getCapitalProjectsIgrBreakdown();

            if(data!= null || data != undefined)
            {
                this.chart2.options = {
                    series: [
                        {
                            name: 'Number of Physical Projects',
                            data: data.data
                        }
                    ],
                    chart: {
                        type: 'bar',
                        height: '100%',
                    },
                    dataLabels :{
                        enabled: false
                    },
                    plotOptions:{
                        bar: {
                            distributed: true,
                            columnWidth: '55%'
                        }
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette2'
                    }
                };
            }
        },
        async createChartThree(){
            const data = await getCapitalProjectsFgnBreakdown();

            if(data!= null || data != undefined)
            {
                this.chart3.options = {
                    series: [
                        {
                            name: 'Number of Physical Projects',
                            data: data.data
                        }
                    ],
                    chart: {
                        type: 'bar',
                        height: '100%',
                    },
                    plotOptions:{
                        bar: {
                            distributed: true,
                            columnWidth: '55%'
                        }
                    },
                    dataLabels :{
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette2'
                    }
                };
            }
        },
        async  createChartFour(){
            const data = await getCapitalProjectsTetfundBreakdown();

            if(data!= null || data != undefined)
            {
                this.chart4.options = {
                    series: [
                        {
                            name: 'Number of Physical Projects',
                            data: data.data
                        }
                    ],
                    chart: {
                        type: 'bar',
                        height: '100%',
                    },
                    plotOptions:{
                        bar: {
                            distributed: true,
                            columnWidth: '55%'
                        }
                    },
                    dataLabels :{
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette2'
                    }
                };
            }
        }

    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    } 
});
