import { globalProps } from "@/main";

export const getTotalAccommodationCapacity = async () => {
    const url = `${globalProps.$apiUrl}/accommodation/getTotalAccommodationCapacity`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getTotalAccommodationEnergyConsumed = async () => {
    const url = `${globalProps.$apiUrl}/accommodation/getTotalAccommodationEnergyConsumed`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getAccommodationStats = async () => {
    const url = `${globalProps.$apiUrl}/accommodation/getAccommodationStats`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getAccommodationHallNames = async () => {
    const url = `${globalProps.$apiUrl}/accommodation/getAccommodationHallNames`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getAccommodationLocations = async () => {
    const url = `${globalProps.$apiUrl}/accommodation/getAccommodationLocations`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getAccommodationDataByHall = async (hall:string) => {
    const url = `${globalProps.$apiUrl}/accommodation/getAccommodationDataByHall/${hall}`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getAccommodationDataByLocation = async (location:string) => {
    const url = `${globalProps.$apiUrl}/accommodation/getAccommodationDataByLocation/${location}`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getEnergyConsumptionDataByHall = async (hall:string) => {
    const url = `${globalProps.$apiUrl}/accommodation/getEnergyConsumptionDataByHall/${hall}`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getEnergyConsumptionDataByLocation = async (location:string) => {
    const url = `${globalProps.$apiUrl}/accommodation/getEnergyConsumptionDataByLocation/${location}`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};