import { globalProps } from "@/main";

export const getStats = async () => {
    const url = `${globalProps.$apiUrl}/itmo/getStats`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getInnovationBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/itmo/getInnovationBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getInnovationDetailsBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/itmo/getInnovationDetailsBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getResearchesBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/itmo/getResearchesBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getIpBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/itmo/getIpBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};