
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { globalProps } from "@/main";
import { ArrowPathRoundedSquareIcon} from '@heroicons/vue/24/outline';
import ChartModel from '@/components/charts/ChartModel.vue';
import {getLibraryResourceBreakdown, getElectronicResourceBreakdown, getUserBreakdown, 
    getAlternativeResourceBreakdown, getBorrowerFullBreakdown, getBorrowerBreakdown} from '@/service/library.service';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel
    },
    data(){
        return {
            interval:0,
            timeout: 0,
            chart1: {
                options: {}
            },
            chart2: {
                options: {}
            },
            chart3: {
                options: {}
            },
            chart4: {
                options: {}
            },
            chart5: {
                options: {}
            },
            chart6: {
                options: {}
            }
        }
    },
    mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }  
        
        this.refreshLibData();

        if(this.$route.name == "library")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshLibData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"nonAcademicStaff"})}, globalProps.$change_page_frequency_seconds);

        }
        else{
            clearInterval(this.interval);
        }
    },
    methods: {
        async refreshLibData() {
            if(this.$route.name == "library")
            {
                this.createChartOne();
                this.createChartTwo();
                this.createChartThree();
                this.createChartFour();
                this.createChartFive();
                this.createChartSix();
            }
        },
        async createChartOne(){
            const data = await getLibraryResourceBreakdown();

            if(data != null || data != undefined){
                this.chart1.options = {
                    series: data.data,
                    chart: {
                        type: 'bar',
                        height: '100%'
                    },
                    plotOptions: {
                        bar: {
                            horizontal:false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        }
                    },
                    dataLabels:{
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: data.labels
                    },
                    fill: {
                        opacity: 1
                    },
                    theme: {
                        palette: 'palette2'
                    }
                };
            }

        },
        async createChartTwo(){
            const data = await getElectronicResourceBreakdown();

            if(data != null || data != undefined){
                this.chart2.options = {
                    series: data.data,
                    chart: {
                        type: 'line',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight',
                    },
                    xaxis: {
                        categories: data.labels
                    },
                    theme: {
                        palette: 'palette2'
                    }
                };
            }
        },
        async createChartThree(){
            const data = await getUserBreakdown();

            if(data != null || data != undefined){
                this.chart3.options = {
                    series: data.data,
                    chart: {
                        type: 'area',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: data.labels
                    },
                    theme: {
                        palette: 'palette2'
                    }
                };
            }
        },
        async createChartFour(){
            const data = await getAlternativeResourceBreakdown();

            if(data != null || data != undefined){
                this.chart4.options = {
                    series: data.data,
                    chart: {
                        type: 'bar',
                        height: '100%'
                    },
                    plotOptions: {
                        bar: {
                            distributed: false,
                            horizontal:false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                        }
                    },
                    dataLabels:{
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: data.labels
                    },
                    fill: {
                        opacity: 1
                    },
                    theme: {
                        palette: 'palette2'
                    }
                };
                
            }
        },
        async createChartFive(){
            const data = await getBorrowerBreakdown();

            if(data != null || data != undefined){

                this.chart5.options = {
                    series: data.data,
                    chart: {
                        type: 'donut',
                        height: '100%',
                        toolbar: {
                            show: true
                        }
                    },
                    labels: data.labels,
                    dataLabels:{
                        enabled: false
                    },
                    theme: {
                        palette: 'palette2'
                    }
                };
                
            }
        },
        async createChartSix(){
            const data = await getBorrowerFullBreakdown();

            if(data != null || data != undefined){
                this.chart6.options = {
                    series: data.data,
                    chart: {
                        type: 'bar',
                        height: '100%'
                    },
                    plotOptions: {
                        bar: {
                            horizontal:false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        }
                    },
                    dataLabels:{
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: data.labels
                    },
                    fill: {
                        opacity: 1
                    },
                    theme: {
                        palette: 'palette2'
                    }
                };
            }
        },
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
});
