
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { globalProps } from "@/main";
import  ApexCharts from 'apexcharts';
import {getTableData,getBottomPublicationFaculties,getTopPublicationFaculties,
    getBottomPublicationDepartment, getTopPublicationDepartment, getPublicationCountYears,
    getPublisherCount, getPubTypeBreakdown, getSparklineData} from '@/service/academicPublication.service'
import { ArrowPathRoundedSquareIcon} from '@heroicons/vue/24/outline';


export default defineComponent({

    components:{
        NavBar,
        ArrowPathRoundedSquareIcon
    },
    data() {
        return {
            interval: 0,
            timeout: 0,
            headers: [],
            tableData: [],
            chart1:{
                show: false
            },
            chart2:{
                show: false
            },
            chart3:{
                show: false
            },
            chart4:{
                show: false
            },
            chart5:{
                show: false
            },
            chart6:{
                show: false
            },
            chart7:{
                show: false
            },
            chart8:{
                show: false
            },
            chart9:{
                show: false
            },
            chart10:{
                show: false
            }
        }
    },
    mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }  
        else{
            this.createChartTen();
            this.createChartNine();
            this.createChartEight();
            this.createChartSeven();
            this.createChartSix();
            this.createChartThreeFive();
            this.createChartFour();
            this.createChartTwo();
            this.createChartOne();

            if(this.$route.name === "academicPublication")
            {
                if(this.interval > 0){
                    clearInterval(this.interval);
                }
                this.interval = setInterval(this.refreshAcpData, globalProps.$graphRefreshTimerMilliseconds);
                this.timeout = setTimeout(()=>{ this.$router.push({name:"academicStaff"})}, globalProps.$change_page_frequency_seconds);

            }
            else{
                clearInterval(this.interval);
            }

        } 
    },
    methods: {
        async createChartOne(){
            const data = await getPublicationCountYears();

            if(data != null || data != undefined)
            {
                const options = {
                    series: [{
                        name: 'Number of Publications',
                        data: data.data
                    }],
                    chart: {
                        type: 'area',
                        height: '100%',
                        zoom: {
                            enabled:"true"
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    xaxis:{
                        categories: data.categories,
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette3'
                    }
                };

                this.chart1.show = true;

                let ctx = document.querySelector("#acpChart1");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicPublication"){
                    chart.render();
                }

            }
        },
        async createChartTwo(){
            const data = await getPublisherCount();

            if(data != null || data != undefined)
            {
                const options = {
                    series: data.data,
                    chart:{
                        type: 'donut',
                        toolbar:{
                            show:"true"
                        }
                    },
                    height:'100%',
                    labels: data.categories,
                    dataLabels: {
                        enabled: true
                    },
                    legend: {
                        show: false
                    },
                    theme:{
                        palette: 'palette3'
                    }
                };

                this.chart2.show = true;

                let ctx = document.querySelector("#acpChart2");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicPublication"){
                    chart.render();
                }
            }
        },
        async createChartFour()
        {
            const data = await getPubTypeBreakdown();

            if(data != null || data != undefined)
            {
                const options = {
                    series: data.data,
                    labels: data.categories,
                    chart: {
                        type: 'radialBar',
                        toolbar:{
                            show:"true"
                        }
                    },
                    height:'100%',
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '10px',
                                },
                                value: {
                                    fontSize: '16px',
                                }
                            }
                        }
                    },
                    theme: {
                        palette:"palette3"
                    }
                };

                this.chart4.show = true;

                let ctx = document.querySelector("#acpChart4");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicPublication"){
                    chart.render();
                }
            }
        },
        async createChartThreeFive(){
            const data = await getSparklineData();

            if(data != null || data != undefined)
            {
                const topAuthor = data.category1[0]
                const bottomAuthor = data.category1[1]

                // Chart3
                let options = {
                    chart: {
                        id: 'sparkline1',
                        type: 'line',
                        height: '100%',
                        sparkline: {
                            enabled: true
                        },
                        group: 'sparklines',
                        toolbar:{
                            show:"true"
                        }
                    },
                    labels: data.category2,
                    series: [{
                        name: `Number of Book Published - ${topAuthor}`,
                        data: data.data[topAuthor].series
                    }],
                    stroke: {
                        curve: 'smooth'
                    },
                    markers: {
                        size: 0
                    },
                    tooltip: {
                        fixed: {
                        enabled: true,
                        position: 'right'
                        },
                        x: {
                        show: true
                        }
                    },
                    title: {
                        text: `${topAuthor} - ${data.data[topAuthor].total}`,
                        style: {
                        fontSize: '26px'
                        }
                    },
                    colors: ['#D4526E']
                };

                this.chart3.show = true;

                let ctx = document.querySelector("#acpChart3");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicPublication"){
                    chart.render();
                }

                // Chart5
                options = {
                    chart: {
                        id: 'sparkline2',
                        type: 'line',
                        height: '100%',
                        sparkline: {
                        enabled: true
                        },
                        group: 'sparklines',
                        toolbar:{
                            show:"true"
                        }
                    },
                    labels: data.category2,
                    series: [{
                        name: `Number of Book Published - ${bottomAuthor}`,
                        data: data.data[bottomAuthor].series
                    }],
                    stroke: {
                        curve: 'smooth'
                    },
                    markers: {
                        size: 0
                    },
                    tooltip: {
                        fixed: {
                            enabled: true,
                            position: 'right'
                        },
                        x: {
                            show: true
                        }
                    },
                    title: {
                        text: `${bottomAuthor} - ${data.data[bottomAuthor].total}`,
                        style: {
                            fontSize: '26px'
                        }
                    },
                    colors: ['#13D8AA']
                }

                this.chart5.show = true;

                ctx = document.querySelector("#acpChart5");
                if(ctx!== null)
                ctx.innerHTML = "";
                chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicPublication"){
                    chart.render();
                }
            }
        },
        async createChartSix(){
            const data = await getTopPublicationDepartment();

            if(data != null || data != undefined)
            {

                const options = {
                    chart:{
                        type: 'bar',
                        height: '100%',
                    },
                    xaxis: {
                        categories: data.categories,
                        position: 'top',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        tooltip: {
                            enabled: true
                        },
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                            formatter: function (val:any) {
                            return val;
                            }
                        },
                        max: data.total
                    },
                    plotOptions: {
                        bar: {
                            distributed: true,
                            columnWidth: '55%'
                        }
                    },
                    series: [
                        {
                            data: data.data,
                            name: 'Number of staff'
                        }
                    ],
                    theme: {
                        palette: 'palette3'
                    }
                };

                this.chart6.show = true;

                let ctx = document.querySelector("#acpChart6");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicPublication"){
                    chart.render();
                }
            }
        },
        async createChartSeven(){
            const data = await getBottomPublicationDepartment();

            if(data != null || data != undefined)
            {
                const options = {
                    xaxis: {
                        categories: data.categories,
                        max: data.total
                    },
                    series: [
                        {
                            name:'Number of staff',
                            data: data.data
                        }
                    ],
                    chart: {
                        type:'bar',
                        height: '100%',

                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            distributed: true

                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    theme: {
                        palette: 'palette3'
                    }
                };

                this.chart7.show = true;

                let ctx = document.querySelector("#acpChart7");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicPublication"){
                    chart.render();
                }

            }
        },
        async createChartEight(){
            const data = await getTopPublicationFaculties();

            if(data != null || data != undefined)
            {

                const options = {
                    chart:{
                        type: 'bar',
                        height: '100%',
                    },
                    xaxis: {
                        categories: data.categories,
                        position: 'top',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        tooltip: {
                            enabled: true
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                            formatter: function (val:any) {
                            return val;
                            }
                        },
                        max: data.total
                    },
                    series: [
                        {
                            data: data.data,
                            Name: 'Number of staff'
                        }
                    ],
                    theme: {
                        palette: 'palette3'
                    },
                    plotOptions: {
                        bar: {
                            distributed:true,
                            columnWidth: '55%'
                        }
                    }
                };

                this.chart8.show = true;

                let ctx = document.querySelector("#acpChart8");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicPublication"){
                    chart.render();
                }
            }
        },
        async createChartNine(){
            const data = await getBottomPublicationFaculties();

            if(data != null || data != undefined)
            {
                const options = {
                    xaxis: {
                        categories: data.categories,
                        max: data.total
                    },
                    series: [
                        {
                            name:'Number of staff',
                            data: data.data
                        }
                    ],
                    chart: {
                        type:'bar',
                        height: '100%'
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            distributed: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    theme: {
                        palette: 'palette3'
                    }
                };

                this.chart9.show = true;

                let ctx = document.querySelector("#acpChart9");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicPublication"){
                    chart.render();
                }
            }
        },
        async createChartTen() {
            const tableInfo = await getTableData();

            if(tableInfo != null || tableInfo != undefined)
            {
                this.chart10.show = true;
                this.headers = tableInfo.headers;
                this.tableData = tableInfo.data;
            }
        },
        async refreshAcpData()
        {
            if(this.$route.name === "academicPublication")
            {
                this.createChartOne();
                this.createChartTwo();
                this.createChartThreeFive();
                this.createChartFour();
                this.createChartSix();
                this.createChartSeven();
                this.createChartEight();
                this.createChartNine();
                this.createChartTen();
            }
            
        }
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }

    
})
