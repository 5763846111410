import { globalProps } from "@/main";

export const getIndustrialTrainingBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/cilpu/getIndustrialTrainingBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getCompanyBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/cilpu/getCompanyBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};