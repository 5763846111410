import {createRouter, createWebHistory} from 'vue-router';

import LoginComp from '@/layout/LoginComp.vue';
import DashboardComp from '@/layout/DashboardComp.vue';
import AcademicPublicationVue from '@/layout/AcademicPublication.vue';
import AcademicStaffVue from '@/layout/AcademicStaff.vue';
import AccommodationVue from '@/layout/AccommodationVue.vue';
import CapitalProjects from '@/layout/CapitalProjects.vue';
import CilpuVue from '@/layout/CilpuVue.vue';
import EnergyConsumption from '@/layout/EnergyConsumption.vue';
import FacultyVue from '@/layout/FacultyVue.vue';
import FireEquipment from '@/layout/FireEquipment.vue';
import FoundationSchool from '@/layout/FoundationSchool.vue';
import Irpp from '@/layout/IrppVue.vue';
import ItmoLayout from '@/layout/ItmoLayout.vue';
import LibraryLayout from '@/layout/LibraryLayout.vue';
import NonAcademicStaff from '@/layout/NonAcademicStaff.vue';
import OfficeOfAdvancement from '@/layout/OfficeOfAdvancement.vue';
import PowerSupply from '@/layout/PowerSupply.vue';
import ProgrammesLayout from '@/layout/ProgrammesLayout.vue';
import StudentsLayout from '@/layout/StudentsLayout.vue';
import DepartmentVue from '@/layout/DepartmentVue.vue';

const vueRoutes = [
    {
        path: '/',
        name: "login",
        component: LoginComp
    },
    {
        path: '/dashboard',
        name: "dashboard",
        component: DashboardComp,
    },
    {
        path:'/academicPublication',
        name:'academicPublication',
        component: AcademicPublicationVue
    },
    {
        path:'/academicStaff',
        name:'academicStaff',
        component: AcademicStaffVue
    },
    {
        path:'/accommodation',
        name:'accommodation',
        component: AccommodationVue
    },
    {
        path:'/capitalProjects',
        name:'capitalProjects',
        component: CapitalProjects
    },
    {
        path:'/cilpu',
        name:'cilpu',
        component: CilpuVue
    },
    {
        path:'/department',
        name:'department',
        component: DepartmentVue
    },
    {
        path:'/energyConsumption',
        name:'energyConsumption',
        component: EnergyConsumption
    },
    {
        path:'/faculty',
        name:'faculty',
        component: FacultyVue
    },
    {
        path:'/fireEquipment',
        name:'fireEquipment',
        component: FireEquipment
    },
    {
        path:'/foundationSchool',
        name:'foundationSchool',
        component: FoundationSchool
    },
    {
        path:'/irpp',
        name:'irpp',
        component: Irpp
    },
    {
        path:'/itmo',
        name:'itmo',
        component: ItmoLayout
    },
    {
        path:'/library',
        name:'library',
        component: LibraryLayout
    },
    {
        path:'/nonAcademicStaff',
        name:'nonAcademicStaff',
        component: NonAcademicStaff
    },
    {
        path:'/officeOfAdvancement',
        name:'officeOfAdvancement',
        component: OfficeOfAdvancement
    },
    {
        path:'/powerSupply',
        name:'powerSupply',
        component: PowerSupply
    },
    {
        path:'/programmes',
        name:'programmes',
        component: ProgrammesLayout
    },
    {
        path:'/students',
        name:'students',
        component: StudentsLayout
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: vueRoutes
})

export default router;