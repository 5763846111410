
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { globalProps } from "@/main";
import { ArrowPathRoundedSquareIcon} from '@heroicons/vue/24/outline';
import ChartModel from '@/components/charts/ChartModel.vue';
import {getDepartmentBreakdown, getStatusBreakdown} from '@/service/programme.service';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel
    },
    data() {
        return {
            interval: 0,
            timeout: 0,
            chart1:{
                options: {}
            },
            chart2: {
                options: {}
            }
        }
    },
    mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }

        this.refreshProgData();

        if(this.$route.name == "programmes")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshProgData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"students"})}, globalProps.$change_page_frequency_seconds);
        }
        else{
            clearInterval(this.interval);
        }
    },
    methods: {
        async refreshProgData() {
            if(this.$route.name == "programmes")
            {
                this.createChartOne();
                this.createChartTwo();
            }
        },
        async createChartOne(){
            const data = await getDepartmentBreakdown();

            if(data != null || data != undefined)
            {
                this.chart1.options = {
                    xaxis: {
                        categories: data.labels,
                    },
                    series: data.data,
                    chart: {
                        type:'bar',
                        height: data.labels.length * 25 > 1123 ? data.labels.length * 25 : '100%',

                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            distributed: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend:{
                        show: false
                    },
                    theme: {
                        palette: 'palette3'
                    },
                    colors: ['#EF476E', '#FFD166', '#06D69F', '#118AB2', '#073B4C']
                };
            }
        },
        async createChartTwo(){
            const data = await getStatusBreakdown();

            if(data != null || data != undefined){
                this.chart2.options = {
                    series: data.data,
                    chart: {
                        type: 'pie',
                        height: '100%',
                        toolbar: {
                            show: true
                        }
                    },
                    labels: data.labels,
                    theme: {
                        palette: 'palette3'
                    },
                    colors: ['#EF476E', '#FFD166', '#06D69F', '#118AB2', '#073B4C']
                };
            }
        }
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
});
