
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { globalProps } from "@/main";
import { ArrowPathRoundedSquareIcon, UsersIcon, GlobeAltIcon ,BuildingOfficeIcon} from '@heroicons/vue/24/outline';
import ChartModel from '@/components/charts/ChartModel.vue';
import StatChartModel from '@/components/charts/StatChartModel.vue';
import { getStats, getProgramTypeBreakdown, getDepartmentBreakdown, getGenderBreakdown,
    getSemesterAbroadBreakdown, getCommunityServiceBreakdown, getFinancialAidBreakdown,
    getCountryBreakdown, getStateBreakdown, getCategoryByProgrammeTypeBreakdown,
    getFacultyBreakdown, getDisabilityBreakdown, getEntryModeBreakdown, getGraduatedBreakdown,
    getEmployedBreakdown, getFinancialAidByCategoryBreakdown, getSemesterAbroadByProgrammeTypeBreakdown, getCommunityServiceByGenderBreakdown } from '@/service/students.service';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel, StatChartModel
    },
    data() {
        return {
            interval: 0,
            timeout: 0,
            chart1:{
                totalDepartment :0,
                totalStudents: 0,
                totalFaculty: 0,
                totalCountries: 0,
                departmentIcon: ArrowPathRoundedSquareIcon,
                studentIcon: UsersIcon,
                FacultyIcon: BuildingOfficeIcon,
                CountryIcon: GlobeAltIcon,
            },
            chart2:{
                options: {}
            },
            chart3:{
                options: {}
            },
            chart4:{
                options: {}
            },
            chart5:{
                options: {}
            },
            chart6:{
                options: {}
            },
            chart7:{
                options: {}
            },
            chart8:{
                options: {}
            },
            chart9:{
                options: {}
            },
            chart10:{
                options: {}
            },
            chart11:{
                options: {}
            },
            chart12:{
                options: {}
            },
            chart13:{
                options: {}
            },
            chart14:{
                options: {}
            },
            chart15:{
                options: {}
            },
            chart16:{
                options: {}
            },
            chart17:{
                options: {}
            },
            chart18:{
                options: {}
            }
        }
    },
    mounted(){
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }

        this.refreshStudentData();

        if(this.$route.name == "students")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshStudentData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"dashboard"})}, globalProps.$change_page_frequency_seconds);

        }
        else{
            clearInterval(this.interval);
        }
    },
    methods: {
        async refreshStudentData() {
            if(this.$route.name == "students"){
                this.createChartOne();
                this.createChartTwo();
                this.createChartThree();
                this.createChartFour();
                this.createChartFive();
                this.createChartSix();
                this.createChartSeven();
                this.createChartEight();
                this.createChartNine();
                this.createChartTen();
                this.createChartEleven();
                this.createChartTwelve();
                this.createChartThirteen();
                this.createChartFourteen();
                this.createChartFifteen();
                this.createChartSixteen();
                this.createChartSeventeen();
                this.createChartEighteen();
            }
        },
        async createChartOne(){
            const data = await getStats();

            if(data!= null || data!= undefined)
            {
                this.chart1.totalCountries = data.countryCount;
                this.chart1.totalDepartment = data.departmentCount;
                this.chart1.totalFaculty = data.facultyCount;
                this.chart1.totalStudents = data.studentCount;

                const counter1 = (this.$refs["std1"] as any);
                const counter2 = (this.$refs["std2"] as any);
                const counter3 = (this.$refs["std3"] as any);
                const counter4 = (this.$refs["std4"] as any);

                if(counter1 != null || counter1 != undefined)
                {
                    counter1.reRunAnimation();
                }

                if(counter2 != null || counter2 != undefined)
                {
                    counter2.reRunAnimation();
                }

                if(counter3 != null || counter3 != undefined)
                {
                    counter3.reRunAnimation();
                }

                if(counter4 != null || counter4 != undefined)
                {
                    counter4.reRunAnimation();
                }

            }

        },
        async createChartTwo(){
            const data = await getProgramTypeBreakdown();

            if(data != null || data != undefined){
                this.chart2.options = {
                    series: data.data,
                    chart: {
                        type: 'polarArea',
                        height: '100%',
                        toolbar: {
                            show: true
                        }
                    },
                    labels: data.labels,
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };
            }

        },
        async createChartThree(){
            const data = await getDepartmentBreakdown();

            if(data != null || data != undefined){
                this.chart3.options = {
                    xaxis: {
                        categories: data.labels,
                    },
                    series: data.data,
                    chart: {
                        type:'bar',
                        height: data.labels.length * 25 > 1123 ? data.labels.length * 25 : '100%',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            distributed: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: false
                    },
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };
            }
        },
        async createChartFour(){
            const data = await getGenderBreakdown();

            if(data != null || data != undefined){
                this.chart4.options = {
                    series: data.data,
                    chart: {
                        type: 'pie',
                        height: '100%',
                        toolbar: {
                            show: true
                        }
                    },
                    labels: data.labels,
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };
            }
        },
        async createChartFive(){
            const data = await getSemesterAbroadBreakdown();

            if(data != null || data != undefined){
                this.chart5.options= {
                    series: data.data,
                    chart: {
                        type: 'pie',
                        height: '100%',
                        toolbar: {
                            show: true
                        }
                    },
                    labels: data.labels,
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };
            }
        },
        async createChartSix(){
            const data = await getCommunityServiceBreakdown();

            if(data != null || data != undefined){
                this.chart6.options= {
                    series: data.data,
                    chart: {
                        type: 'pie',
                        height: '100%',
                        toolbar: {
                            show: true
                        }
                    },
                    labels: data.labels,
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };
            }
        },
        async createChartSeven(){
            const data = await getFinancialAidBreakdown();

            if(data != null || data != undefined){
                this.chart7.options = {
                    series: data.data,
                    chart: {
                        type: 'pie',
                        height: '100%',
                        toolbar: {
                            show: true
                        }
                    },
                    labels: data.labels,
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };
                
            }
        },
        async createChartEight(){
            const data = await getCountryBreakdown();

            if(data != null || data != undefined){
                this.chart8.options = {
                    xaxis: {
                        categories: data.labels,
                    },
                    series: data.data,
                    chart: {
                        type:'bar',
                        height: data.labels.length * 25 > 1123 ? data.labels.length * 25 : '100%',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            distributed: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend:{
                        show: false
                    },
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };
            }
        },
        async createChartNine(){
            const data = await getStateBreakdown();

            if(data != null || data != undefined){
                this.chart9.options = {
                    series: data.data,
                    legend: {
                        show: false
                    },
                    chart: {
                        height: '100%',
                        type: 'treemap'
                    },
                    plotOptions: {
                        treemap: {
                            distributed: true,
                            enableShades: false
                        }
                    },
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                }
            }
        },
        async createChartTen(){
            const data = await getCategoryByProgrammeTypeBreakdown();

            if(data != null || data != undefined){
                this.chart10.options = {
                    series: data.data,
                    chart:{
                        type:'heatmap',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis:{
                        title:{
                            text: "Programme type"
                        }
                    },
                    yaxis:{
                        title:{
                            text: "Student Category"
                        }
                    },
                    colors: ['#1AB0D0']

                };                
            }
        },
        async createChartEleven(){
            const data = await getFacultyBreakdown();


            if(data != null || data != undefined){
                this.chart11.options = {
                    xaxis: {
                        categories: data.labels,
                    },
                    series: data.data,
                    chart: {
                        type:'bar',
                        height: data.labels.length * 25 > 1123 ? data.labels.length * 25 : '100%',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            distributed: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend:{
                        show: false
                    },
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };                
            }
        },
        async createChartTwelve(){
            const data = await getDisabilityBreakdown();

            if(data != null || data != undefined){
                this.chart12.options={
                    series: data.data,
                    chart: {
                        type: 'radialBar',
                        width: '85%',
                        toolbar: {
                            show: true
                        }
                    },
                    plotOptions:{
                        raidalBar: {
                            dataLabels: {
                                total: {
                                    show: false
                                }
                            }
                        }
                    },
                    labels: data.labels,
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']

                };
            }
        },
        async createChartThirteen(){
            const data = await getEntryModeBreakdown();

            if(data != null || data != undefined){
                this.chart13.options= {
                    series: data.data,
                    chart: {
                        type: 'radialBar',
                        width: '85%',
                        toolbar: {
                            show: true
                        }
                    },
                    plotOptions:{
                        raidalBar: {
                            dataLabels: {
                                total: {
                                    show: false
                                }
                            }
                        }
                    },
                    labels: data.labels,
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };
            }
        },
        async createChartFourteen(){
            const data = await getGraduatedBreakdown();

            if(data != null || data != undefined){
                this.chart14.options = {
                    series: data.data,
                    chart: {
                        type: 'radialBar',
                        width: '85%',
                        toolbar: {
                            show: true
                        }
                    },
                    plotOptions:{
                        raidalBar: {
                            dataLabels: {
                                total: {
                                    show: false
                                }
                            }
                        }
                    },
                    labels: data.labels,
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                }
            }
        },
        async createChartFifteen(){
            const data = await getEmployedBreakdown();

            if(data != null || data != undefined){
                this.chart15.options = {
                    series: data.data,
                    chart: {
                        type: 'radialBar',
                        width: '85%',
                        toolbar: {
                            show: true
                        }
                    },
                    plotOptions:{
                        raidalBar: {
                            dataLabels: {
                                total: {
                                    show: false
                                }
                            }
                        }
                    },
                    labels: data.labels,
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };
                
            }
        },
        async createChartSixteen(){
            const data = await getFinancialAidByCategoryBreakdown();

            if(data != null || data != undefined){
                this.chart16.options= {
                    series: data.data,
                    chart:{
                        type: 'bubble',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        opacity: 0.8
                    },
                    // xaxis:{
                    //     type:'category',
                    // },
                    // yaxis: {
                    //     type:'category',
                    // },
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']
                };
            }
        },
        async createChartSeventeen(){
            const data = await getSemesterAbroadByProgrammeTypeBreakdown();

            if(data != null || data != undefined){
                this.chart17.options = {
                    series: data.data,
                    chart: {
                        type: 'radar',
                        height: '100%'
                    },
                    xaxis: {
                        categories: data.labels
                    },
                    colors: ['#1F497D', '#C0E752', '#1AB0D0', '#EB5757', '#78CDB0', '#FFBA08', '#93D5E3', '#AB2346', '#4B77AF', '#4BB16E']

                };
            }
        },
        async createChartEighteen(){
            const data = await getCommunityServiceByGenderBreakdown();

            if(data != null || data != undefined){
                this.chart18.options = {
                    series: data.data,
                    chart:{
                        type:'heatmap',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ['#AB2346'],
                    xaxis:{
                        title:{
                            text:"Gender"
                        }
                    },
                    yaxis: {
                        title: {
                            text: "Community Service?"
                        }
                    }
                };
                
            }
        }
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
});
