
import { defineComponent } from 'vue';
import { globalProps } from "@/main";
import NavBar from '@/components/nav/NavBar.vue';
import  ApexCharts from 'apexcharts';
import { ArrowPathRoundedSquareIcon, UserGroupIcon, DocumentCheckIcon, AcademicCapIcon, GlobeAltIcon} from '@heroicons/vue/24/outline';
import {getGlancingFacts, getDepartmentBreakdown, getFacultyBreakdown, getGenderBreakdown,getQualificationBreakdown,
    getDesignationBreakdown, getTechnologyTransferBreakdown, getEmploymentBreakdown, getCountryBreakdown, getStateBreakdown } from '@/service/academicStaff.service';
import Vue3Autocounter from 'vue3-autocounter';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, UserGroupIcon, DocumentCheckIcon,
        AcademicCapIcon, GlobeAltIcon, Vue3Autocounter
    },
    data (){
        return {
            interval: 0,
            timeout: 0,
            chart1: {
                staffCount: 0,
                designationCount: 0,
                facultyCount: 0,
                countryCount: 0
            },
            chart2: {
                show: false
            },
            chart3: {
                show: false
            },
            chart4: {
                show: false
            },
            chart5: {
                show: false
            },
            chart6: {
                show: false
            },
            chart7: {
                show: false
            },
            chart8: {
                show: false
            },
            chart9: {
                show: false,
                tableData: [],
                headers: []
            },
            chart10: {
                show: false,
                countryToSearch: "",
                existingSearch: false
            }
        }
    },
    created(){
        this.createChartOne();
    },
    mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        }
        else{
            this.createChartNine();
            this.createChartEight();
            this.createChartSeven();
            this.createChartSix();
            this.createChartFive();
            this.createChartFour();
            this.createChartThree();
            this.createChartTwo();

            if(this.$route.name === "academicStaff")
            {
                if(this.interval > 0){
                    clearInterval(this.interval);
                }
                this.interval = setInterval(this.refreshAcsData, globalProps.$graphRefreshTimerMilliseconds);
                this.timeout = setTimeout(()=>{ this.$router.push({name:"accommodation"})}, globalProps.$change_page_frequency_seconds);

            }
            else{
                clearInterval(this.interval);
            }
        }
    },
    methods: {
        async refreshAcsData() {
            if(this.$route.name === "academicStaff")
            {
                this.createChartNine();
                this.createChartEight();
                this.createChartSeven();
                this.createChartSix();
                this.createChartFive();
                this.createChartFour();
                this.createChartThree();
                this.createChartTwo();
                this.createChartOne();

                if(this.chart10.existingSearch)
                {
                    this.createChartTen(this.chart10.countryToSearch)
                }
            }
        },
        async createChartOne() {
            const data = await getGlancingFacts();

            if(data != null || data != undefined)
            {
                this.chart1.countryCount = data.country;
                this.chart1.designationCount = data.designation;
                this.chart1.facultyCount = data.faculty;
                this.chart1.staffCount = data.staff;

                if(this.$route.name === "academicStaff"){

                    const counter1 = (this.$refs.counter1 as any);
                    const counter2 = (this.$refs.counter2 as any);
                    const counter3 = (this.$refs.counter3 as any);
                    const counter4 = (this.$refs.counter4 as any);

                    if(counter1 != null || counter1!= undefined){
                        counter1.start();
                    }

                    if(counter2 != null || counter2!= undefined){
                        counter2.start();
                    }

                    if(counter3 != null || counter3!= undefined){
                        counter3.start();
                    }

                    if(counter4 != null || counter4!= undefined){
                        counter4.start();
                    }
                }
            }
        },
        async createChartTwo(){
            
            const data = await getFacultyBreakdown();

            if(data != null || data != undefined)
            {
                var options = {
                    series: [{
                        data: data.data
                    }],
                    chart: {
                        type: 'bar',
                        height: data.category.length * 25 > 1123 ? data.category.length * 25 : '100%'

                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '55%',
                            borderRadius: 10,
                            distributed: true,
                            horizontal: true,
                            dataLabels: {
                            position: 'bottom'
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            colors: ['#fff']
                        },
                        formatter: function (val:any, opt:any) {
                            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                        },
                        offsetX: 0,
                        dropShadow: {
                            enabled: true
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    xaxis: {
                        categories: data.category,
                        max: data.total
                    },
                    yaxis: {
                        labels: {
                            show: false
                        }
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                                formatter: function () {
                                    return ''
                                }
                            }
                        }
                    }
                };

                this.chart2.show = true;

                let ctx = document.querySelector("#acsChart2");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicStaff"){
                    chart.render();
                }
            }

        },
        async createChartThree(){

            const data = await getDepartmentBreakdown();

            if(data != null || data != undefined)
            {
                let options = {
                    series:[
                        {
                            name:"Number of Staff",
                            data: data.data
                        }
                    ],
                    chart: {
                        height: '99%',
                        type: 'bar'
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 10,
                            columnWidth: '55%',
                            distributed: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: 2
                    },
                    xaxis: {
                        categories: data.category
                    },
                    yaxis: {
                        title: {
                            text: "Number of Academic Staff"
                        },
                        max: data.total
                    }
                };

                this.chart3.show = true;

                let ctx = document.querySelector("#acsChart3");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicStaff"){
                    chart.render();
                }
            }
        },
        async createChartFour(){
            const data = await getGenderBreakdown();
            
            if(data != null || data != undefined)
            {
                const options = {
                    series: data.data,
                    chart: {
                        type: 'pie',
                        height: "100%",
                        toolbar:{
                            show:"true"
                        }
                    },
                    labels: data.category,
                    colors: ['#008FFB','#FF4560']
                };

                this.chart4.show = true;

                let ctx = document.querySelector("#acsChart4");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicStaff"){
                    chart.render();
                }
            }
        },
        async createChartFive(){
            const data = await getQualificationBreakdown();

            if(data != null || data != undefined)
            {
                const options = {
                    series: data.data,
                    chart:{
                        type: 'donut',
                        height: '100%',
                        toolbar:{
                            show:"true"
                        }
                    },
                    labels: data.category,
                    plotOptions: {
                        pie: {
                            startAngle: -90,
                            endAngle: 90,
                            offsetY: 10
                        }
                    },
                };

                this.chart5.show = true;

                let ctx = document.querySelector("#acsChart5");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicStaff"){
                    chart.render();
                }
            }
        },
        async createChartSix(){
            const data = await getDesignationBreakdown();

            if(data != null || data != undefined)
            {
                let options = {
                    series: data.series,
                    legend: {
                        show: false
                    },
                    chart: {
                        height: '100%',
                        type: 'treemap'
                    },
                    plotOptions: {
                        treemap: {
                            distributed: true,
                            enableShades: false
                        }
                    }
                };

                this.chart6.show = true;

                let ctx = document.querySelector("#acsChart6");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicStaff"){
                    chart.render();
                }
            }
        },
        async createChartSeven(){
            const data = await getTechnologyTransferBreakdown();

            if(data != null || data != undefined)
            {
                var options = {
                    series: data.data,
                    chart: {
                        type: 'radialBar',
                        height: '100%',
                        toolbar:{
                            show:"true"
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            offsetY: 0,
                            startAngle: 0,
                            endAngle: 270,
                            hollow: {
                                margin: 5,
                                size: '30%',
                                image: undefined,
                            },
                            dataLabels: {
                                name: {
                                    show: false,
                                },
                                value: {
                                    show: false,
                                }
                            }
                        }
                    },
                    labels: data.category,
                    legend: {
                        show: true,
                        floating: true,
                        fontSize: '16px',
                        position: 'left',
                        offsetX: '1%',
                        offsetY: '1%',
                        labels: {
                            useSeriesColors: true,
                        },
                        markers: {
                            size: 0
                        },
                        formatter: function(seriesName:any, opts:any) {
                            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                        },
                        itemMargin: {
                            vertical: 3
                        }
                    }
                };

                this.chart7.show = true;

                let ctx = document.querySelector("#acsChart7");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicStaff"){
                    chart.render();
                }
            }
        },
        async createChartEight() {
            const data = await getEmploymentBreakdown();

            if(data != null || data != undefined)
            {
                var options = {
                    series: data.data,
                    chart: {
                        type: 'radialBar',
                        height: '100%',
                        toolbar:{
                            show:"true"
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            offsetY: 0,
                            startAngle: 0,
                            endAngle: 270,
                            hollow: {
                                margin: 5,
                                size: '30%',
                                image: undefined,
                            },
                            dataLabels: {
                                name: {
                                    show: false,
                                },
                                value: {
                                    show: false,
                                }
                            }
                        }
                    },
                    labels: data.category,
                    legend: {
                        show: true,
                        floating: true,
                        fontSize: '16px',
                        position: 'left',
                        offsetX: '15%',
                        offsetY: '5%',
                        labels: {
                            useSeriesColors: true,
                        },
                        markers: {
                            size: 0
                        },
                        formatter: function(seriesName:any, opts:any) {
                            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                        },
                        itemMargin: {
                            vertical: 3
                        }
                    }
                };

                this.chart8.show = true;

                let ctx = document.querySelector("#acsChart8");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicStaff"){
                    chart.render();
                }
            }
        },
        async createChartNine(){
            const data = await getCountryBreakdown();

            if(data != null || data != undefined)
            {
                this.chart9.show = true;
                this.chart9.tableData = data.data;
                this.chart9.headers = data.header;
            }
        },
        async createChartTen(country:string) {

            const data = await getStateBreakdown(country);

            if(data != null || data != undefined)
            {
                const options = {
                    series: [
                        {
                            name:'Number of Staff: ',
                            data: data.data
                        }
                    ],
                    chart: {
                        type: 'bar',
                        height: data.category.length * 25 > 1123 ? data.category.length * 25 : '100%',
                        
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '55%',
                            borderRadius: 10,
                            distributed: true,
                            dataLabels: {
                                position: 'bottom'
                            },
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend:{
                        show: false
                    },
                    colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0'],
                    xaxis: {
                        categories: data.category,
                        labels: {
                            style: {
                                colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0'],
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        min: 0,
                        max: data.total
                    }
                };

                this.chart10.show = true;
                this.chart10.existingSearch = true;
                this.chart10.countryToSearch = country;

                let ctx = document.querySelector("#acsChart10");
                if(ctx!== null)
                ctx.innerHTML = "";
                let chart = new ApexCharts(ctx, options);

                if(this.$route.name === "academicStaff"){
                    chart.render();
                }
            }
        }
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
    
})
