import { globalProps } from "@/main";

export const getPhysicalProjectsBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/capitalProjects/getPhysicalProjectsBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getCapitalProjectsIgrBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/capitalProjects/getCapitalProjectsIgrBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getCapitalProjectsFgnBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/capitalProjects/getCapitalProjectsFgnBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getCapitalProjectsTetfundBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/capitalProjects/getCapitalProjectsTetfundBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};