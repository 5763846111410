
import { defineComponent } from 'vue';
import NavBar from '@/components/nav/NavBar.vue';
import { globalProps } from "@/main";
import { ArrowPathRoundedSquareIcon, LightBulbIcon, ScaleIcon, UserIcon, UserGroupIcon} from '@heroicons/vue/24/outline';
import ChartModel from '@/components/charts/ChartModel.vue';
import StatChartModel from '@/components/charts/StatChartModel.vue';
import {getStats, getInnovationBreakdown, getInnovationDetailsBreakdown, 
    getResearchesBreakdown, getIpBreakdown} from '@/service/itmo.service';

export default defineComponent({

    components:{
        NavBar, ArrowPathRoundedSquareIcon, ChartModel, StatChartModel
    },
    data (){
        return {
            interval: 0,
            timeout: 0,
            chart1:{
                totalInnovationCount: 0,
                studentInnovationCount: 0,
                staffInnovationCount: 0,
                ipCount: 0,
                totalInnovation: LightBulbIcon,
                studentInnovation: UserIcon,
                staffInnovation: UserGroupIcon,
                ip: ScaleIcon
            },
            chart2: {
                options: {}
            },
            chart3: {
                options: {}
            },
            chart4: {
                options: {}
            },
            chart5: {
                options: {}
            }
        }
    },
    async created(){
        // await this.createChartOne();
    },
    async mounted() {
        const u = sessionStorage.getItem('user');

        if(u ==null || u == undefined)
        {
            this.$router.push({name:"login"});
        } 
        
        this.refreshItmoData();

        if(this.$route.name === "itmo")
        {
            if(this.interval > 0){
                clearInterval(this.interval);
            }
            this.interval = setInterval(this.refreshItmoData, globalProps.$graphRefreshTimerMilliseconds);
            this.timeout = setTimeout(()=>{ this.$router.push({name:"library"})}, globalProps.$change_page_frequency_seconds);

        }
        else{
            clearInterval(this.interval);
        }
    },
    methods: {
        async refreshItmoData() {
            if(this.$route.name == "itmo")
            {
                
                this.createChartTwo();
                this.createChartThree();
                this.createChartFour();
                this.createChartFive();
                await this.createChartOne();

                if((this.$refs["itmo1"] as any) != null || (this.$refs["itmo1"] as any) != undefined){
                    (this.$refs["itmo1"] as any).reRunAnimation();
                }

                if((this.$refs["itmo2"] as any) != null || (this.$refs["itmo2"] as any) != undefined){
                    (this.$refs["itmo2"] as any).reRunAnimation();
                }

                if((this.$refs["itmo3"] as any) != null || (this.$refs["itmo3"] as any) != undefined){
                    (this.$refs["itmo3"] as any).reRunAnimation();
                }

                if((this.$refs["itmo4"] as any) != null || (this.$refs["itmo4"] as any) != undefined){
                    (this.$refs["itmo4"] as any).reRunAnimation();
                }
            }
        },
        async createChartOne(){
            const data = await getStats();

            if(data != null || data != undefined){
                this.chart1.ipCount = data.ip;
                this.chart1.staffInnovationCount = data.staffInnovation;
                this.chart1.studentInnovationCount = data.studentInnovation;
                this.chart1.totalInnovationCount = data.totalInnovation;
            }
        },
        async createChartTwo(){
            const data = await getInnovationBreakdown();

            if(data != null || data != undefined){
                this.chart2.options = {
                    series: data.data,
                    chart: {
                        type: 'line',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette5'
                    }
                };
                
            }

        },
        async createChartThree(){
            const data = await getInnovationDetailsBreakdown();

            if(data != null || data != undefined){
                this.chart3.options = {
                    series: data.data,
                    chart: {
                        type: 'bar',
                        height: '100%'
                    },
                    plotOptions: {
                        bar: {
                            horizontal:false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        }
                    },
                    dataLabels:{
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    theme: {
                        palette: 'palette5'
                    }
                };
            }

        },
        async createChartFour(){
            const data = await getResearchesBreakdown();

            if(data != null || data != undefined){
                this.chart4.options = {
                    series: data.data,
                    chart: {
                        type: 'area',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    fill: {
                        opacity: 0.8,
                        type: 'pattern',
                        pattern: {
                            style: ['verticalLines', 'horizontalLines'],
                            width: 5,
                            height: 6
                        }
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette5'
                    }
                };
            }

        },
        async createChartFive(){
            const data = await getIpBreakdown();

            if(data != null || data != undefined){
                this.chart5.options = {
                    series: data.data,
                    chart: {
                        type: 'line',
                        height: '100%'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight',
                        dashArray: [7]
                    },
                    xaxis: {
                        categories: data.labels,
                        title:{
                            text:"Year"
                        }
                    },
                    theme: {
                        palette: 'palette5'
                    }
                };
            }
        },
    },
    beforeRouteLeave () {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
});
