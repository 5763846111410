import { globalProps } from "@/main";

export const getFinanceBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/energy/getFinanceBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getRateEnergyBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/energy/getRateEnergyBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getEnergySourceBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/energy/getEnergySourceBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getVehicleDataBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/energy/getVehicleDataBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};