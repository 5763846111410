import { globalProps } from "@/main";

export const getElectricityRateBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/power/getElectricityRateBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getMonthlyPaymentBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/power/getMonthlyPaymentBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getMonthlyDieselBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/power/getMonthlyDieselBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getMonthlyDieselCostBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/power/getMonthlyDieselCostBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};