import { globalProps } from "@/main";

export const getDepartureFacultyBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/department/getDepartureFacultyBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getAccreditationBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/department/getAccreditationBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getCreationDateBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/department/getCreationDateBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};
