import { globalProps } from "@/main";

export const getStats = async () => {
    const url = `${globalProps.$apiUrl}/students/getStats`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getProgramTypeBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getProgramTypeBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getDepartmentBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getDepartmentBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getGenderBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getGenderBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getSemesterAbroadBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getSemesterAbroadBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getCommunityServiceBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getCommunityServiceBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getFinancialAidBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getFinancialAidBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getCountryBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getCountryBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getStateBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getStateBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getCategoryByProgrammeTypeBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getCategoryByProgrammeTypeBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getFacultyBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getFacultyBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getDisabilityBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getDisabilityBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getEntryModeBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getEntryModeBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getGraduatedBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getGraduatedBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getEmployedBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getEmployedBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getFinancialAidByCategoryBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getFinancialAidByCategoryBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getSemesterAbroadByProgrammeTypeBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getSemesterAbroadByProgrammeTypeBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};

export const getCommunityServiceByGenderBreakdown = async () => {
    const url = `${globalProps.$apiUrl}/students/getCommunityServiceByGenderBreakdown`;

    return await fetch(url, {
        method:"GET"
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});
};