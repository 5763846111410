import { globalProps } from "@/main";
export const authenticateUser = async (params: {username:string, password:string}) => {

    const url = `${globalProps.$apiUrl}/ad/authenticate`;

    return await fetch(url, {
        method:"POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return undefined;});

    
};